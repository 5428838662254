body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pn-msg-list {
  --msg-list__background: white;
  --msg__bubble__background: transparent;
  --msg__bubble__color: black;
  --msg__bubble__borderRadius: 2;
  --msg__bubble__padding: 2;
  --msg__bubble__boxShadow: 1;
}

.pn-msg--own {
  --msg__avatar__margin: 2px 0 0 18px;
  --msg__content__alignItems: flex-end;
  --msg__flexDirection: row-reverse;
  --msg__title__flexDirection: row-reverse;
}

input {
  height: 100% !important;
}

.mapbox-searchbox-wrapper input {
  min-height: 40px;
}
